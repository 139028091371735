import React, { useCallback } from "react";
import { UserContext } from "../../context/UserInformation";

const InformationProvider = ({ children }) => {
  const sendMessage = useCallback(async (message) => {
    try {
      const url = new URLSearchParams({ q: message });
      const res = await fetch(
        `https://chat.educli.ai/chat?${url.toString()}`
      ).then((res) => res.json());
      return res.response;
    } catch (error) {
      console.error(error);
    }
  }, []);

  return (
    <UserContext.Provider value={{ sendMessage }}>
      {children}
    </UserContext.Provider>
  );
};

export default InformationProvider;
