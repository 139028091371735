// @ts-ignore
import Chatbot from "react-chatbot-kit";
import "react-chatbot-kit/build/main.css";
import "./App.css";
import config from "./components/config";
import MessageParser from "./components/MessageParser";
import ActionProvider from "./components/ActionProvider";
import ChatIcon from "./ChatIcon";

function App() {
  return (
    <div className="main-chatbot">
      <div className="chatbot-wrapper">
        <Chatbot
          config={config}
          messageParser={MessageParser}
          actionProvider={ActionProvider}
          validator={(input) => (input.length > 0 ? true : false)}
          headerText={
            <div className="chat-header">
              <ChatIcon /> <span>Conversation with educli bot</span>
            </div>
          }
        />
      </div>
    </div>
  );
}

export default App;
